import React, { useEffect, useState , useCallback} from 'react';
import TagManager from 'react-gtm-module';
import './App.css';
import Modal from './components/styles/Modal';
import styled from 'styled-components';

const Button = styled.button`
  border: 2px solid ${props => props?.widgetData?.borderColor || 'white'};
  border-radius: 8px;
  padding: ${props => props?.widgetData?.padding || '10px'};
  font-size: ${props => props?.widgetData?.fontsize || '20px'};
  font-family:${props => props?.widgetData?.fontFamily || 'sans-serif'};
  font-weight: 500;
  text-align: center;
  width: ${props => props?.widgetData?.width || '100%'};
  background-color: ${props => props?.widgetData?.primaryColor || '#ffa600'};
  color: ${props => props?.widgetData?.textColor || 'white'};
  cursor: pointer;
`;

//justify-content: center;

const AppContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 600px;
  width: 100%;
`;

function App(props) {

  const widgetDataObj = JSON.parse(JSON.stringify({ ...props.domElement.dataset }));
  const widgetData = JSON.parse(widgetDataObj['widgetdata']); // Moved this line up

  const addStats = useCallback((dealer_unique_id) => {
      const payload = {
        "dealer_unique_id" : dealer_unique_id,
        "session_id": "",
        "campaign_type": "web",
        "entry": "init",
        "step": "component_load",
        "substep": "",
        "data_1": "",
        "data_2": "",
        "data_3": "",
        "browser_stats": "",
        "appt_time": "",
        "appt_date": ""
      };
  
      fetch('https://api-prospect.nomodealerhub.com/grl/addStats', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Apikey': '52253ec3-2dfd-4d4b-8388-ea80462bce45'
        },
        body: JSON.stringify(payload),
      })
      .then(response => response.json())
      .then(data => {
        ///console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    
  }, []); 
  

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-K9GRMN6",
    };
    TagManager.initialize(tagManagerArgs);

    addStats(widgetData.dealer_unique_id);
  }, [addStats, widgetData.dealer_unique_id]);

  
 
  let url = "https://app.gorillaroofleads.com/home?dealer_unique_id=" + widgetData.dealer_unique_id

  //<Home widgetData={widgetData}></Home>
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  






  return (
    <AppContent>
      <Button widgetData={widgetData} onClick={openModal}>{widgetData?.buttonText || 'Get Roof Estimate'}</Button>
      <Modal 
        show={showModal} 
        onClose={closeModal}
        title=""
      >
        <iframe title="Roof Lead" src={url} width="100%" height="100%" style={{ border: 'none', borderRadius: '5px' }}>
          Your browser does not support iframes.
        </iframe>   
      </Modal>
    </AppContent>
  );
}

export default App;
